import { Component } from '@angular/core';
import { menuItems } from './menu.constants';

@Component({
  selector   : 'lgnd-menu',
  templateUrl: './menu.component.html',
  styleUrls  : ['./menu.component.scss']
})
export class MenuComponent {
  private menuItems: object[] = [ ...menuItems ];
}