import { Component } from '@angular/core';
import routes from '../../app-routing.module';
import {
  trigger,
  transition,
  query,
  state,
  style,
  stagger,
  animate
} from '@angular/animations';

@Component({
  selector: 'lgnd-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('navAnimation', [
      transition('collapsed => expanded', [
        query('@linkAnimation', [
          style({
            opacity: 0,
            transform: 'translateX(-10px)'
          }),
          stagger('60ms cubic-bezier(.72,.11,.7,.94)',
            animate('300ms 400ms ease-out', style({
              opacity: 1,
              transform: 'translateX(0px)'
            })))
        ], { optional: true })
      ])
    ]),
    trigger('linkAnimation', [
      state('collapsed', style({
        opacity: 0
      })),
      transition('expanded => collapsed', animate('250ms cubic-bezier(0,.38,.07,1)', style({
        opacity: 0,
        transform: 'translateX(-15px)'
      })))
    ])
  ]
})
export class NavbarComponent {
  public nav    : string[] = [];
  public navOpen: boolean = false;

  constructor() {
    routes.forEach( el => this.nav.push( el.path ) )
  }

  public navClicked() {
    this.navOpen = !this.navOpen;
  }

  closeNav() {
    this.navOpen = false;
  }
}
