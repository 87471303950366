import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GamingComponent } from './gaming.component';

@NgModule({
  declarations: [GamingComponent],
  imports: [
    CommonModule
  ]
})
export class GamingModule { }
