import { Component } from '@angular/core';
import { cocktails, beers, wines } from './drinks.constants';

@Component({
  selector   : 'lgnd-drinks',
  templateUrl: './drinks.component.html',
  styleUrls  : ['./drinks.component.scss']
})
export class DrinksComponent {
  private cocktails: object[] = [ ...cocktails ];
  private beers:     object[] = [ ...beers ];
  private wines:     object[] = [ ...wines ];
}