import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EsportsComponent } from './esports.component';

@NgModule({
  declarations: [EsportsComponent],
  imports: [
    CommonModule
  ]
})
export class EsportsModule { }
