import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrinksComponent } from './drinks.component';

@NgModule({
  declarations: [DrinksComponent],
  imports: [
    CommonModule
  ]
})
export class DrinksModule { }
