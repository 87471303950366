export const menuItems = [
  {
    title              : "Guacamole",
    description        : "Home-made chunky guacamole, that means chunks of fresh avocado, fresh tomato and fresh onions with some fresh cilantro and big squeeze of fresh lime juice... it's fresh.",
    dietaryRestrictions: "(v)(vg)(gf)"
  },
  {
    title              : "Elote",
    description        : "Also known as \"Mexican Corn\", these little delights are rolled in a light coating of mayo & butter, then topped with cotija cheese and drizzled with lime juice.",
    dietaryRestrictions: "(gf)"
  },
  {
    title : "Wings",
    styles: [
      {
        type               : "Grilled",
        description        : "Grilled to perfection with dry rub, and no breading to be heard of, served as individually wrapped.",
        dietaryRestrictions: "(gf)"
      },
      {
        type       : "Fried",
        description: "Deep fried to a mouth water flavor bomb, served as individually wrapped and assorted sauces of your choice.",
      },
      {
        type               : "Frenched",
        description        : "Frenched and roasted w/ house dry rub, no breading to be heard of, served as individually wrapped.",
        dietaryRestrictions: "(gf)"
      }
    ]
  },
  {
    title : "Baby Back Riblets",
    styles: [
      {
        type       :"Six or Ten",
        description: "We've slathered the best baby-back riblets in our house BBQ sauce straight from Louisiana"
      }
    ]
  },
  {
    title              : "Bruschetta & Mozzarella",
    description        : "Tomato, basil, salt and extra virgin olive oil over thin slice of fresh mozzarella place on a crunchy sliver of french bagguette",
    dietaryRestrictions: "(v)(vg)"
  },
  {
    title      : "Potato Munchers",
    description: "Balls of potato, cheddar cheese, bacon, and those little green onions... Nuff said"
  },
  {
    title      : "Large Toasted Ravioli",
    description: "Italian spices, ricotta, smoked gouda, and parsley baked in a large pasta ravioli served with a side of marinara for one handed dipping."
  },
  {
    title      : "Crab Cakes",
    description: "Cakes of crabs... We're keeping this one to ourselves ;) (Please let us know of any allergies or dietary restrictions when ordering)"
  },
  {
    title              : "Spring Rolls",
    description        : "Veggie filled classic spring roles presented with multiple east asian dipping sauces",
    dietaryRestrictions: "(v)"
  },
  {
    title              : "Falafel",
    description        : "Chickpeas, onion, garlic, parsley, coriander, and cumin fried in a light oil to perfection.",
    dietaryRestrictions: "(v)(vg)"
  },
  {
    title      : "Meat Balls",
    description: "Our hand rolled and lightly spiced balls of meat, come in the following flavors:",
    styles     : [
      { type: "Swedish", description: "Onions and spices and butter, in a lingonberry cream sauce" },
      { type: "Sweet & Sour", description: "Asian spices in a sweet and sour sauce"},
      { type: "Teriyaki", description: "The style name says it all, classic terriaky" },
      { type: "Chorizo", description: "You got it, we bade chorizo and spice meaty balls" }
    ]
  },
  {
    title      : "Skewers",
    description: "Flavored sticks of steak and chicken... what's your sauce?"
  },
  {
    title      : "Lamb Chops ",
    description: "Rosemary, dill a dash od salt end pepper then seared to the perfect doneness... yeah we take \"medium\" seriously."
  },
  {
    title      : "Salad Wrap",
    description: "It's a hand salad, or a salad for your hand, well use your mouth for the chewing bit at least...",
    styles     : [
      {
        type: "Caesar w/ + Chicken",
        description: "Classic chicken caesar wrap with a little too much dressing, if you don't like dressing, let us know to tone it down on this one and we'll put it on the side"
      },
      {
        type       : "Italian",
        description: "All the Italian meats and spices and some cheese, and of course fat-free italian dressing."
      },
      {
        type               : "Quinoa Veggie",
        description        : "You'd have to ask the chef for details beyond 'quinoa' and 'lettuce', because it's market fresh!",
        dietaryRestrictions: "(v)(vg)"
      },
      {
        type               : "Fruit",
        description        : "For all you lovers of fresh fruit, we got this selection from the market today. You may not have heard of them all, but it's ALL good and can even come in a gluten free wrap.",
        dietaryRestrictions: "(v)(vg)(gf)"
      },
      {
        type       : "Chef’s Ready",
        description: "On hand meats, cheeses and veggies... put yourself in our chef's hands."
      }
    ]
  },
  {
    title      : "Tacos",
    description: "Three street tacos topped with our house guacamole, pico de gallo, or salsas.",
    styles     : [
      {
        type       : "Chicken",
        description: "Just baked chicken sliced to thin perfection and topped with your choice of salsa"
      },
      {
        type       : "Beef",
        description: "Lean sliced beef with mexican spices and a heaping mound of our house guacamole"
      },
      {
        type       : "Chorizo",
        description: "That sweet pork sausage and of course out house guacamole"
      },
      {
        type               : "Veggie",
        description        : "Chilies, cabbage, garlic, red pepper and onion with black beans and our house guacamole",
        dietaryRestrictions: "(v)(vg)"
      }
    ]
  },
  {
    title : "Slider(s)",
    description: "Mini-burger style sandwiches, we can do a lot here, don't be afraid to make special requests",
    styles: [
      {
        type               : "1 UP ( mushroom  )",
        description        : "Mushrooms, red onions, rolled oats, sunflower seeds, bread crumbs and dill topped with a mango chutney arugala, and carmelized red onions",
        dietaryRestrictions: "(v)(vg)"
      },
      {
        type       : "Angus Beef",
        description: "You meatatarian... this one is for you! Toppings, no toppings, we don't care, let us know, we'll do it... Most important though, we take \"Medium\" seriously here, so request doneness with that in mind"
      },
      {
        type               : "Tuna",
        description        : "I have no idea what they did to this giant hunk of tuna and what ever the sauce is, it's amazing too. Just give it a try!",
        dietaryRestrictions: "(P)escatarian"
      },
      {
        type       : "Croquettes",
        description: "Okay, no buns on this one... This is a potato wrapped, deep fried burger. Let us know what you think this means, and we'll fill it with those ingredients"
      },
      {
        type       : "Buffalo Chicken Ranch( Grilled / Fried )",
        description: "Market fresh chicken your style slathered in buffalo sauce, with lettuce, tomato, cheese and topped with ranch"
      },
      {
        type               : "Impossible",
        description        : "We've got that Impossible(tm) beef... but it's not beef. Let's be real, this is amazing. Oh yeah and there's not real cheese on here too. Lettuce. Tomato. And however they make that darn not beef and not cheese. 10/10",
        dietaryRestrictions: "(v)(vg)"
      },
      {
        type               : "Veggie",
        description        : "Black beans, bell pepper, onion, garlic, and chili powder topped with lettuce, tomato and red onion",
        dietaryRestrictions: "(v)(vg)"
      }
    ]
  },
  {
    title      : "Flat Bread",
    description: "Flat breads set for single hand clean delivery to the mouth. We like pizza, but we want more flavor and toppings than bread and hope you agree!",
    styles     : [
      {
        type       : "BBQ Chicken",
        description: "Louisiana BBQ sauce drizzled over chunks of mouth watering chicken, red onions, and bell peppers and mozzarella cheese",
      },
      {
        type       : "Cheese",
        description: "Four different artesian cheeses... yeah nobody knows what that means, but it's a great cheese pie"
      },
      {
        type: "Pepperoni",
        description: "Slathered in cheese and topped with a lot of pepperoni, we assume you like that because you ordered it. Let us know if you don't want it basically covered in pepperonis"
      },
      {
        type: "Gyro",
        description: "Lamb & Beef classic gyro with red onions and tomatoes slathered in tzatziki. "
      },
      {
        type: "TMNT",
        description: "Basically a supreme pizza 'Teenage Mutant Ninja Turtle' style."
      },
      {
        type       : "Chef’s Ready",
        description: "You could get lucky and end up with ham and pineapple on the same pie, but... I wouldn't count on it."
      }
    ]
  },
  {
    title: "Fries",
    description: "These come in a paper cone, just the right size so if you're keeping them to yourselves, you can one hand it and keep them fingers clean for those triggers",
    styles: [
      { type: "Idaho", dietaryRestrictions: "(v)(vg)(gf)" },
      { type: "Sweet Potato", dietaryRestrictions: "(v)(vg)(gf)" },
      { type: "Truffle & Parmesan" }
    ]
  },
  {
    title      : "Loaded Fries",
    description: "These come in a tray with mini-forks... clean fingers, yay, more play time!",
    styles: [
      {
        type       : "Twice Baked Style",
        description: "Covered in melted cheddar cheese, bacon, green onions, and sour cream. Good luck keeping this to yourself..."
      },
      {
        type       : "Poutine",
        description: "Covered in cheese curds and beef gravy?! Sounds 'slappin' as the kids say..."
      }
    ]
  },
  {
    title: "Baby Gyros",
    description: "Basically gyros, but smaller, and we have options!",
    styles: [
      { type: "Lamb/Beef" },
      { type: "Chicken" },
      { type: "Falafel" },
    ]
  },
  {
    title: "Sausages",
    styles: [
      { type: "Bratwurst" },
      { type: "Chicken" },
      { type: "This week’s exotic", description: "Could be snake, rabbit, alligator... Your server will tell you what it is when it arrives. ;)" },
      { type: "Vegan", dietaryRestrictions: "(v)(vg)" },
    ]
  },
  {
    title: "Rock Sirloin",
    description: "Huge piece of USDA choice steak, wrapped in prosciutto, mushrooms and shallots, on a false bone wrapped in puff pastry. Anyone remember Zelda? Straight out of the game."
  },
  {
    title: "Pasta Roll",
    description: "This egg roll looking pasta delight is a new spin on a pasta roll-up with classic flavors: ",
    styles: [
      { type: "Carbonara", description: "Bacon, egg, garlic, parmesan cheese, parsley, & extra virgin olive oil" },
      { type: "Lasagña", description: "Lean ground beef, spiced italian sausage, onion, garlic, tomato, basil, fennel, parsely, ricotta, parmesian, and mozzarella" },
      { type: "Chicken Pesto", description: "Chicken, garlic, sun-dried tomatoes, and pesto sauce" },
    ]
  }
]