import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComingsoonComponent } from './comingsoon.component';

@NgModule({
  declarations: [ComingsoonComponent],
  imports: [
    CommonModule
  ]
})
export class ComingsoonModule { }
