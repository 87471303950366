import { Component } from '@angular/core';
import { trigger, transition, style, animate, group, query } from '@angular/animations';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routeAnimations', [
      transition('* <=> *', [
        style({ position: 'relative' }),
        query(':enter, :leave', style({
          position: 'absolute', top: 0, left: 0, width: '100%'
        }), { optional: true }),
        group([
          query(':leave', [
            group([
              animate('300ms cubic-bezier(.55,.5,.39,.98)', style({
                opacity: 0,
                transform: 'translateY(100%)'
              }))
            ])
          ], { optional: true }),
          query(':enter', [
            style({
              transform: 'translateY(-100%)',
            }),
            animate('300ms cubic-bezier(.55,.5,.39,.98)', style({
              transform: 'translateY(0%)',
            }))
          ], { optional: true }),
          query(':enter', [
            style({
              transform: 'translateY(-100%)',
              opacity: 0
            }),
            animate('600ms 200ms cubic-bezier(.55,.5,.39,.98)', style({
              transform: 'translateY(0%)',
              opacity: 1
            }))
          ], { optional: true }),
        ])
        // query(':leave', [
        //   animate('300ms ease-out', style({
        //     opacity: 0
        //   }))
        // ], { optional: true })
      ])
    ])
  ]
})
export class AppComponent {
  title = 'legends-bar';

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
