import { ComingsoonModule } from './components/comingsoon/comingsoon.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { GamingModule } from './components/gaming/gaming.module';
import { TeamModule } from './components/team/team.module';
import { HomePageModule } from './components/home-page/home-page.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarModule } from './components/navbar/navbar.module';
import { NewsModule } from './components/news/news.module';
import { MenuModule } from './components/menu/menu.module';
import { DrinksModule } from './components/drinks/drinks.module';
import { EsportsModule } from './components/esports/esports.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HomePageModule,
    NavbarModule,
    TeamModule,
    NewsModule,
    MenuModule,
    DrinksModule,
    GamingModule,
    EsportsModule,
    ComingsoonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
