import { HomePageComponent } from './components/home-page/home-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DrinksComponent } from './components/drinks/drinks.component';
import { TeamComponent } from './components/team/team.component';
import { MenuComponent } from './components/menu/menu.component';
import { NewsComponent } from './components/news/news.component';
import { GamingComponent } from './components/gaming/gaming.component';
import { EsportsComponent } from './components/esports/esports.component';
import { ComingsoonComponent } from './components/comingsoon/comingsoon.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    data: { animation: 'Home' }
  },
  {
    path: 'esports',
    component: EsportsComponent,
    data: { animation: 'Esports' }
  },
  {
    path: 'gaming',
    component: GamingComponent,
    data: { animation: 'Gaming' }
  },
  // {
  //   path: 'events',
  //   component: ComingsoonComponent,
  //   data: { animation: 'Events' }
  // },
  {
    path: 'menu',
    component: MenuComponent,
    data: { animation: 'Menu' }
  },
  {
    path: 'drinks',
    component: DrinksComponent,
    data: { animation: 'Drinks' }
  },

  // {
  //   path: 'competition',
  //   component: ComingsoonComponent,
  //   data: { animation: 'Competition' }
  // },
  {
    path: 'news',
    component: NewsComponent,
    data: { animation: 'News' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export default routes